<template>
  <v-app>
    <div class="row mt-1">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Candidate practice exam result</h4>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr class="text-left">
                    <th class="px-3" style="max-width: 90px !important; white-space: pre-wrap;">Enrolment key</th>
                    <th class="px-3" style="max-width: 90px !important; white-space: pre-wrap;">Mark obtained</th>
                    <th class="px-3" style="max-width: 90px !important; white-space: pre-wrap;">Certificate</th>
                    <th class="px-3" style="max-width: 90px !important; white-space: pre-wrap;">Result sent date</th>
                    <th class="px-3">Added by</th>
                    <th class="px-3">Examiner</th>
                    <th class="px-3">Mark type</th>
                    <th class="px-3">Official</th>
                    <th class="">Options</th>
                  </tr>
                </thead>
                <tbody>
                  <template>
                    <tr
                      v-for="(item, index) in practical_exam_results"
                      :key="index"
                    >
                      <td class="text-left">
                        <div class="font-weight-bold ">
                          {{ item.candidate_full_name ? item.candidate_full_name : "--" }}
                        </div>
                        <a
                          href="javascript:;"
                          class="
                            text-primary
                            font-weight-bolder
                            text-hover-primary
                            mb-1
                            font-size-lg
                          "
                        >
                          {{ item.candidate_exam_key ? item.candidate_exam_key : "" }}
                        </a>
                      </td>
                      <td class="text-left">
                        {{ item.mark_obtained }}
                      </td>
                      <td class="text-left">
                        <div 
                          class="badge" :class="item.certificate_print_status ? 'badge-primary' : 'badge-danger'" style="max-width: 100px !important; white-space: pre-wrap;">
                          {{ item.certificate_print_status ? 'Printed' : 'Not printed'}}
                        </div>
                        <div v-if="item.certificate_printed_date"
                          class="ml-2 mt-2"
                          style="max-width: 100px !important; white-space: pre-wrap; font-size: small;">
                          Printed on {{ item.certificate_printed_date }}
                        </div>
                      </td>
                      <td class="text-left">
                        {{ item.dispatched_date ? item.dispatched_date : '-' }}
                      </td>
                      <td class="text-left">
                        {{ item.addedBy ? item.addedBy.full_name : "--" }}
                      </td>
                      <td class="text-left">
                        {{ item.examiner_name ? item.examiner_name : "--" }}
                      </td>
                      <td class="text-left">
                        {{ item.mark_type_text ? item.mark_type_text : '--'}}
                      </td>
                      <td class="text-left">
                        <div
                          class="badge"
                          v-bind:class="{
                            'badge-success': item.is_main,
                            'badge-danger': !item.is_main,
                          }"
                        >
                          {{ item.is_main ? "Yes" : "No" }}
                        </div>
                      </td>
                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                                <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div v-if="!item.is_official" class="navi navi-hover min-w-md-250px">
                                <b-dropdown-text tag="div" class="navi-item">
                                  <a  @click="previewReport(item)" class="navi-link">
                                  <span class="navi-icon">
                                      <i class="fas fa-eye"></i>
                                  </span>
                                    <span class="navi-text">Preview report</span>
                                  </a>
                                </b-dropdown-text>
                            </div>
                          </b-dropdown>
                        </template>
                        </td>
                    </tr>
                  </template>
                  <tr v-if="practical_exam_results.length == 0">
                    <td colspan="13" class="text-center">No items Found</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import CandidatePracticalExamResultService from "@/services/candidate/practical-exam-result/CandidatePracticalExamResultService";

const practicalExamResult = new CandidatePracticalExamResultService();

export default {
  name: "PracticalExamResult",
  props: ["candidateId"],
  data() {
    return {
      practical_exam_results: [],
    };
  },
  methods: {
    getPracticalExamResults() {
      practicalExamResult
        .getByCandidate(this.candidateId)
        .then((response) => {
          this.practical_exam_results = response.data.examResult;
        })
        .catch((err) => {});
    },
    previewReport(item){
      if(item.report_type=='scanned'){
        if(item.file_attachment){
          window.open(item.file_attachment, "_blank");
        }else{
          this.$snotify.error("Report not found");
        }

      }else{
        practicalExamResult
        .previewExamReport(item.id)
        .then(response => {

        })
        .catch(error => {
          this.isLoading = false;

        })
      }
    },
  },
  mounted() {
    this.getPracticalExamResults();
  },
};
</script>