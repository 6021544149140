import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class CandidatePracticalExamResultService
{
    #api = null;
    constructor() {
        this.#api = API_URL + 'admin/candidate-practical-exam-result';
    }
    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    getUnlockReportRequests(data={},index = null) {
        let url = `${this.#api}/unlock-report-requests/get`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    getAllPraticalResultByCandidate(data={},index = null,candidateId) {
        let url = `${this.#api}/by-candidate/${candidateId}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    create(data) {

        let url = `${this.#api}`;
        return apiService.post(url, data);

    }
    getByCandidate(id) {
        let url = `${this.#api}/get/by/candidate/${id}`
        return apiService.get(url)
    }
    checkDigitalReport(id)
    {
        let url = `${this.#api}/check/digital-pdf/${id}`;
        return apiService.get(url)
    }
    checkMarkType(id)
    {
        let url = `${this.#api}/check/mark-type/${id}`;
        return apiService.get(url);
    }
    findByCandidateId(data={}){
        let url = `${this.#api}/find-by-candidateId`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    previewExamReport(id)
    {
        let url = `${this.#api}/preview-pdf/${id}`
        window.open(url, "_blank");
    }
    
    getMainResult(id)
    {
        let url = `${this.#api}/main-result/${id}`
        return apiService.get(url);
    }

    downloadEmptyReport(id)
    {
        let url = `${this.#api}/download-empty-report/${id}`
        window.open(url, "_blank");
    }
    previewPrintCertificate(id)
    {
        let url = `${this.#api}/print-certificate/practical/${id}`
        window.open(url, "_blank");
    }
    
    unlockReportByAdmin(resultId, data={}){
        let url = `${this.#api}/${resultId}/unlock-report`;
        return apiService.post(url, data);
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }
    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
    confirmPracticalExamResult(id)
    {
        let url=`${this.#api}/confirm-practical-exam/${id}`;
        return apiService.get(url);
    }
    setAsMainExamReport(id)
    {
        let url=`${this.#api}/set-as-main/${id}`;
        return apiService.get(url);
    }

    getPendingReports(data={},index = null) {
        let url = `${this.#api}/pending/reports`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    getPendingCertificates(data={},index = null) {
        let url = `${this.#api}/pending/certificates`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    getPendingNotifications(data={},index = null){
        let url = `${this.#api}/pending/notifications`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    getPracticalPendingPplateCertificates(data={},index = null) {
        let url = `${this.#api}/pending/pplate-certificates`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    getPendingLabels(data={},index = null) {
        let url = `${this.#api}/pending/labels`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    unlockMarkingForExaminer(resultId){
        let url = `${this.#api}/${resultId}/unlock-examiner-request`
        return apiService.post(url)
    }

    ignoreUnlockMarkingForExaminer(resultId){
        let url = `${this.#api}/${resultId}/ignore-unlock-examiner-request`
        return apiService.post(url)
    }

}
